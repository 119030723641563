// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipe-microcms-recipe-recipe-id-js": () => import("./../../../src/pages/recipe/{microcmsRecipe.recipeId}.js" /* webpackChunkName: "component---src-pages-recipe-microcms-recipe-recipe-id-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-tag-microcms-tag-tag-id-js": () => import("./../../../src/pages/tag/{microcmsTag.tagId}.js" /* webpackChunkName: "component---src-pages-tag-microcms-tag-tag-id-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */)
}

