/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location }) => {
    if (location.pathname === "/recipes" || location.pathname === "/recipes/") {
        const searchtext = getParam(`q`);
        if (searchtext) {
            var form = document.getElementById("form");
            form.searchtext.value = searchtext;
            document.getElementById("searchbutton").click();
        }
    }
};

function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}